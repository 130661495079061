<template>
    <div
        :class="{
            [$style['content']]: true,
            [$style['content_inverted']]: inverted
        }"
    >
        <div
            v-for="(row, index) in content"
            :key="index"
            :class="$style['content__row']"
        >
            <div :class="[$style['content__text'], 'sw-text']">
                {{ row.text }}
            </div>

            <div :class="[$style['content__text'], 'sw-text']">
                {{ row.additionalText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CustomContent',

  props: {
    content: {
      type: Array,
      default: () => []
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "../../../../assets/Web.less";

.content {
  color: @black-text;

  &_inverted { color: #FFFFFF; }

  &__row + &__row {
    margin-top: 24px;
  }

  &__row &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__text:last-child {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>

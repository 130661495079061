<template>
    <div
        :class="{
            [$style['document-link']]: true,
            [$style['document-link_inverted']]: inverted
        }"
        @click.stop="onClick"
    >
        <SwLink
            :class="$style['document-link__sw-link']"
            :inverted="inverted"
            black
        >
            <slot />
        </SwLink>
    </div>
</template>

<script>
export default {
  name: 'DocumentLink',

  props: {
    name: {
      type: String,
      default: ''
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      if (this.$store.state.admin) {
        this.$store.commit('modals/open', 'forbidden')
      }

      this.$store.dispatch('openPublicDocument', this.name)
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.document-link {
  & &__sw-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.4;

    &:hover {
      color: @black-text;
      opacity: 0.6;
    }
  }

  &_inverted &__sw-link:hover {
    color: #FFFFFF;
  }
}
</style>

<template>
    <div
        :class="{
            'sw-text': true,
            [$style['site-copyright']]: true,
            [$style['site-copyright_inverted']]: inverted
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
  name: 'SiteCopyright',

  props: {
    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.site-copyright {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: @grey-900;

  &_inverted {
    color: rgba(255, 255, 255, 0.4);
  }
}
</style>

<template>
    <div
        :class="{
            [$style['menu']]: true,
            [$style['menu_vertical']]: vertical
        }"
    >
        <div
            v-for="(link, index) in links"
            :key="index + '-' + link.position"
            :class="$style['menu__item']"
        >
            <SwLink
                v-if="link.url.includes('//')"
                :class="$style['menu__link']"
                :href="link.url"
                :inverted="inverted"
                target="_blank"
                black
            >
                {{ link.name }}
            </SwLink>

            <SwLink
                v-else
                :class="$style['menu__link']"
                :inverted="inverted"
                :to="link.url"
                black
            >
                {{ link.name }}
            </SwLink>
        </div>

        <div
            v-if="!vertical && links.slice(5).length > 0"
            :class="{
                [$style['more']]: true,
                [$style['more_inverted']]: inverted,
            }"
            @click.stop="opened = true"
        >
            <SvgSpriteMore />
        </div>

        <div
            v-show="opened"
            ref="dropdown"
            :class="[$style['popup'], 'sw-popup']"
            :style="{
                backgroundColor: bgColor
            }"
        >
            <MenuItem
                v-for="(item, index) in links.slice(5)"
                :key="index"
                :name="item.name"
                :link="item.link"
                :inverted="inverted"
            />
        </div>
    </div>
</template>

<script>
import MenuItem from '@@/website/components/common/MenuItem'
import SvgSpriteMore from './CustomMenu/sprite-more.svg'

export default {
  name: 'CustomMenu',

  components: {
    MenuItem,
    SvgSpriteMore
  },

  props: {
    links: {
      type: Array,
      default: () => []
    },

    bgColor: {
      type: String,
      default: ''
    },

    inverted: {
      type: Boolean,
      default: false
    },

    vertical: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    opened: false
  }),

  mounted () {
    const onMissClick = (event) => {
      if (this.$refs.dropdown !== event.target) {
        this.opened = false
      }
    }

    document.addEventListener('click', onMissClick)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('click', onMissClick)
    })
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -12px;
  margin-right: -12px;
  position: relative;

  @media screen and (max-width: @bp-mobile-small) {
    flex-direction: column;
  }

  &_vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    display: inline-block;
    text-decoration: none;
    padding-left: 12px;
    padding-right: 12px;

    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
      text-align: center;

      & + & {
        margin-top: 24px;
      }
    }

    &:nth-child(n + 6) {
      display: none;
      @media screen and (max-width: @bp-mobile-small) {
        display: block;
      }
    }

    &:last-of-type {
      @media screen and (max-width: @bp-mobile-small) {
        margin-bottom: 0;
      }
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &_vertical &__item {

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-child(n + 6) {
      display: inherit;
    }

    @media screen and (max-width: @bp-mobile-small) {
      text-align: left;
    }
  }

  &_vertical &__item + &__item {
    margin-top: 8px;
  }

  &__link {
    display: block;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.more {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  color: @black-text;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: @bp-mobile-small) {
    display: none;
  }

  &:hover { color: var(--sw-color-main-700); }
  &:active { color: var(--sw-color-main-600); }

  &&_inverted {
    color: #FFFFFF;

    &:hover {
      opacity: 0.65;
    }
  }
}

.popup {
  padding: 4px;
  border: 1px solid #FFFFFF !important;
  position: absolute;
  bottom: 25px;
  right: -9px;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid #FFFFFF;
    border-right: 10px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 10px solid transparent;
    margin-left: -10px;
    position: absolute;
    right: 8px;
    bottom: -22px;
  }
}
</style>

var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',{class:_vm.$style['container'],attrs:{"bg-color":_vm.config.background_color_footer_all,"bg-opacity":_vm.config.background_opacity_footer_all,"fluid":"","py0":""}},[_c('div',{class:( _obj = {}, _obj[_vm.$style['footer']] = true, _obj[_vm.$style['footer_type2']] = _vm.variant === _vm.VARIANTS.MENU, _obj[_vm.$style['footer_type3']] = _vm.variant === _vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL, _obj[_vm.$style['footer_type4']] = _vm.variant === _vm.VARIANTS.LOGO_N_MENU_N_SOCIAL, _obj )},[_c('div',{class:_vm.$style['footer__main']},[(_vm.variant !== _vm.VARIANTS.MENU)?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_logo']]},[(_vm.logo)?_c('SwImage',{class:( _obj$1 = {}, _obj$1[_vm.$style['footer__logo']] = true, _obj$1[_vm.$style['footer__logo_left']] = [
                            _vm.VARIANTS.LOGO_N_MENU_N_SOCIAL,
                            _vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL ].includes(_vm.variant), _obj$1 ),attrs:{"src":_vm.logo,"width":"200","height":"50","processing":"none"}}):_c('div',{staticStyle:{"width":"200px"}})],1):_vm._e(),_vm._v(" "),([
                    _vm.VARIANTS.LOGO_N_MENU_N_SOCIAL,
                    _vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL,
                    _vm.VARIANTS.MENU ].includes(_vm.variant))?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_menu']]},[_c('CustomMenu',{class:_vm.$style['footer__menu'],attrs:{"bg-color":_vm.config.background_color_footer_all,"inverted":_vm.inverted,"links":_vm.config.link_items_footer_all,"vertical":_vm.variant === _vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL}})],1):_vm._e(),_vm._v(" "),(_vm.config.content_stat_text_all && [
                    _vm.VARIANTS.LOGO_N_NAME,
                    _vm.VARIANTS.MENU ].includes(_vm.variant))?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_copyright']]},[_c('SiteCopyright',{attrs:{"inverted":_vm.inverted}},[_vm._v("\n                    "+_vm._s(_vm.config.content_stat_text_all)+"\n                ")])],1):_vm._e(),_vm._v(" "),(_vm.config.content_stat_additional_text_all && [
                    _vm.VARIANTS.LOGO_N_NAME,
                    _vm.VARIANTS.MENU ].includes(_vm.variant))?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_email']]},[_c('SwEmail',{attrs:{"email":_vm.config.content_stat_additional_text_all,"inverted":_vm.inverted}})],1):_vm._e(),_vm._v(" "),(
                    _vm.variant === _vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL &&
                        _vm.config.content_dynamic_items_all &&
                        _vm.config.content_dynamic_items_all.length > 0
                )?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_content']]},[_c('CustomContent',{attrs:{"content":_vm.config.content_dynamic_items_all,"inverted":_vm.inverted}})],1):_vm._e(),_vm._v(" "),([_vm.VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL, _vm.VARIANTS.LOGO_N_MENU_N_SOCIAL].includes(_vm.variant))?_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_social-networks']]},[_c('div',{class:_vm.$style['footer__social-networks']},_vm._l((_vm.config.social_network_items_all),function(network,index){return _c('SocialNetworkLink',{key:network.key + '-' + index,attrs:{"icon":network.key,"url":network.url}})}),1)]):_vm._e()]),_vm._v(" "),_c('div',{class:[_vm.$style['footer__area'], _vm.$style['footer__area_documents']]},[_c('DocumentLink',{class:_vm.$style['footer__document-link'],attrs:{"inverted":_vm.inverted,"name":"document_privacy_policy"}},[_vm._v("\n                "+_vm._s(_vm.$t('nav_privacy_policy'))+"\n            ")]),_vm._v(" "),_c('SvgSpriteBullet',{class:( _obj$2 = {}, _obj$2[_vm.$style['footer__bullet']] = true, _obj$2[_vm.$style['footer__bullet_inverted']] = _vm.inverted, _obj$2 )}),_vm._v(" "),_c('DocumentLink',{class:_vm.$style['footer__document-link'],attrs:{"inverted":_vm.inverted,"name":"document_public_offer"}},[_vm._v("\n                "+_vm._s(_vm.$t('nav_public_offer'))+"\n            ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
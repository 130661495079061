<template>
    <SwContainer
        :bg-color="config.background_color_footer_all"
        :bg-opacity="config.background_opacity_footer_all"
        :class="$style['container']"
        fluid
        py0
    >
        <div
            :class="{
                [$style['footer']]: true,
                [$style['footer_type2']]: variant === VARIANTS.MENU,
                [$style['footer_type3']]: variant === VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL,
                [$style['footer_type4']]: variant === VARIANTS.LOGO_N_MENU_N_SOCIAL,
            }"
        >
            <div :class="$style['footer__main']">
                <div
                    v-if="variant !== VARIANTS.MENU"
                    :class="[$style['footer__area'], $style['footer__area_logo']]"
                >
                    <SwImage
                        v-if="logo"
                        :src="logo"
                        :class="{
                            [$style['footer__logo']]: true,
                            [$style['footer__logo_left']]: [
                                VARIANTS.LOGO_N_MENU_N_SOCIAL,
                                VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL,
                            ].includes(variant),
                        }"
                        width="200"
                        height="50"
                        processing="none"
                    />

                    <div
                        v-else
                        style="width: 200px"
                    />
                </div>

                <div
                    v-if="[
                        VARIANTS.LOGO_N_MENU_N_SOCIAL,
                        VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL,
                        VARIANTS.MENU,
                    ].includes(variant)"
                    :class="[$style['footer__area'], $style['footer__area_menu']]"
                >
                    <CustomMenu
                        :bg-color="config.background_color_footer_all"
                        :class="$style['footer__menu']"
                        :inverted="inverted"
                        :links="config.link_items_footer_all"
                        :vertical="variant === VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL"
                    />
                </div>

                <div
                    v-if="config.content_stat_text_all && [
                        VARIANTS.LOGO_N_NAME,
                        VARIANTS.MENU,
                    ].includes(variant)"
                    :class="[$style['footer__area'], $style['footer__area_copyright']]"
                >
                    <SiteCopyright :inverted="inverted">
                        {{ config.content_stat_text_all }}
                    </SiteCopyright>
                </div>

                <div
                    v-if="config.content_stat_additional_text_all && [
                        VARIANTS.LOGO_N_NAME,
                        VARIANTS.MENU,
                    ].includes(variant)"
                    :class="[$style['footer__area'], $style['footer__area_email']]"
                >
                    <SwEmail
                        :email="config.content_stat_additional_text_all"
                        :inverted="inverted"
                    />
                </div>

                <div
                    v-if="
                        variant === VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL &&
                            config.content_dynamic_items_all &&
                            config.content_dynamic_items_all.length > 0
                    "
                    :class="[$style['footer__area'], $style['footer__area_content']]"
                >
                    <CustomContent
                        :content="config.content_dynamic_items_all"
                        :inverted="inverted"
                    />
                </div>

                <div
                    v-if="[VARIANTS.LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL, VARIANTS.LOGO_N_MENU_N_SOCIAL].includes(variant)"
                    :class="[$style['footer__area'], $style['footer__area_social-networks']]"
                >
                    <div :class="$style['footer__social-networks']">
                        <SocialNetworkLink
                            v-for="(network, index) in config.social_network_items_all"
                            :key="network.key + '-' + index"
                            :icon="network.key"
                            :url="network.url"
                        />
                    </div>
                </div>
            </div>

            <div :class="[$style['footer__area'], $style['footer__area_documents']]">
                <DocumentLink
                    :class="$style['footer__document-link']"
                    :inverted="inverted"
                    name="document_privacy_policy"
                >
                    {{ $t('nav_privacy_policy') }}
                </DocumentLink>

                <SvgSpriteBullet
                    :class="{
                        [$style['footer__bullet']]: true,
                        [$style['footer__bullet_inverted']]: inverted,
                    }"
                />

                <DocumentLink
                    :class="$style['footer__document-link']"
                    :inverted="inverted"
                    name="document_public_offer"
                >
                    {{ $t('nav_public_offer') }}
                </DocumentLink>
            </div>
        </div>
    </SwContainer>
</template>

<script>
import CustomContent from './BlockFooter/CustomContent'
import CustomMenu from './BlockFooter/CustomMenu'
import DocumentLink from './BlockFooter/DocumentLink'
import SiteCopyright from './BlockFooter/SiteCopyright'
import SocialNetworkLink from '@@/website/components/common/SocialNetworkLink'
import SvgSpriteBullet from './BlockFooter/sprite-bullet.svg'

const VARIANTS = {
  LOGO_N_NAME: 'logo_and_name',
  LOGO_N_MENU_N_SOCIAL: 'logo_menu_items_and_social_networks',
  LOGO_N_VERTICAL_MENU_N_CONTENT_N_SOCIAL: 'logo_social_networks_and_menu_items_in_3_columns',
  MENU: 'menu_items'
}

export default {
  name: 'BlockFooter',

  components: {
    CustomContent,
    CustomMenu,
    DocumentLink,
    SiteCopyright,
    SocialNetworkLink,
    SvgSpriteBullet
  },

  props: {
    variant: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS,

    /**
     * @returns {boolean}
     */
    inverted () {
      return this.config.inverted_font_color_footer_all
    },

    /**
     * @returns {string}
     */
    logo () {
      return this.config.logo_image_for_footer_all
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.container {
  margin-top: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
  padding-top: 48px;
  padding-bottom: 20px;

  @media screen and (max-width: @bp-mobile-big) {
    padding-bottom: 16px;
  }
  @media screen and (max-width: @bp-mobile-small) {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  &_type3 {
    @media screen and (max-width: @bp-mobile-small) {
      align-items: flex-start;
      padding-left: 16px;
    }
  }

  &_type4 {
    align-items: flex-start;
    @media screen and (max-width: @bp-mobile-small) {
      align-items: center;
    }
  }

  &__logo {
    display: block;

    &.sw-image {
      border-radius: 0;
    }

    &_left {
      object-position: left center;
      margin: 0 !important;
    }
  }

  &_type4 &__logo&__logo_left {
    @media screen and (max-width: @bp-mobile-small) {
      object-position: center;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: -12px;
    margin-right: -12px;
  }

  &_type3 &__main {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    @media screen and (max-width: @bp-mobile-small) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  &_type4 &__main {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: @bp-tablet) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: @bp-mobile-small) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__area {
    margin-bottom: 24px;
  }

  &__main &__area {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__area_logo {
    width: 200px;
    max-height: 80px;
    overflow: hidden;

    img,
    svg {
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 50px;
      border-radius: 0;
      margin: auto;
    }

    svg { width: auto; }
  }

  &_type3 &__area_logo {
    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
    }
  }

  &__area_menu {
    display: flex;
    flex-grow: 1;
    justify-content: center;

    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__main &__area_menu {
    @media screen and (max-width: @bp-mobile-small) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_type2 &__menu {
    text-align: center;

    a {
      max-width: 140px;
      @media screen and (max-width: @bp-mobile-small) {
        max-width: 100%;
      }
    }
  }

  &_type3 &__menu {
    width: 230px;

    @media screen and (max-width: @bp-tablet) { width: 160px; }
    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
    }
  }

  &_type4 &__menu {
    a {
      @media screen and (max-width: @bp-mobile-small) {
        max-width: 100%;
      }
    }
  }

  &__area_copyright {
    text-align: center;
    margin-top: 24px;
  }

  &__area_email {
    margin-top: 24px;
  }

  &__area_content {
    word-break: break-word;
    overflow: hidden;
  }

  &_type3 &__area_content {
    flex-grow: 1;
    @media screen and (max-width: @bp-tablet) { width: 160px; }
    @media screen and (max-width: @bp-mobile-small) { width: 100%; }
  }

  &_type3 &__area_social-networks {
    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
    }
  }

  &_type3 &__area_social-networks,
  &_type4 &__area_social-networks {
    margin-left: auto;
    @media screen and (max-width: @bp-mobile-small) { margin-left: 0; }
  }

  &_type4 &__area_social-networks {
    @media screen and (max-width: @bp-mobile-big) { width: 100%; }
  }

  &__social-networks {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 20px;
  }

  &_type3 &__social-networks {
    @media screen and (max-width: @bp-mobile-small) {
      margin-top: 32px;
    }
  }

  &_type4 &__social-networks {
    @media screen and (max-width: 1120px) {
      max-width: 136px;
      min-width: 136px;
    }

    @media screen and (max-width: @bp-mobile-big) {
      max-width: 292px;
      min-width: 188px;
      margin-left: auto;
    }

    @media screen and (max-width: @bp-mobile-miny) {
      max-width: 136px;
      min-width: 136px;
    }

    @media screen and (max-width: @bp-mobile-small) {
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__area_documents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0;

    @media screen and (max-width: @bp-mobile-big) { margin-top: 8px; }
    @media screen and (max-width: @bp-mobile-small) { margin-top: 16px; }
  }

  &_type3 &__area_documents {
    @media screen and (max-width: @bp-mobile-small) { align-items: flex-start; }
  }

  &_type4 &__area_documents {
    align-self: center;
    @media screen and (max-width: @bp-tablet) { margin-top: 24px; }
  }

  &__area_documents {
    @media screen and (max-width: @bp-mobile-small) {
      flex-direction: column;
      margin-top: 32px;
    }
  }

  &__document-link {
    @media screen and (max-width: @bp-mobile-small) {
      & ~ & { margin-top: 8px; }
    }
  }

  &__bullet {
    color: @black-text;
    margin: 0 16px;
    @media screen and (max-width: @bp-mobile-small) { display: none; }
    @media screen and (max-width: @bp-tablet) { margin: 0; }

    &_inverted { color: #FFFFFF }
  }
}
</style>
